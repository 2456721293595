import Slider from 'javascripts/globals/slider';
import { BREAKPOINTS } from 'javascripts/constants';

const mql = [
  window.matchMedia(`(min-width: ${BREAKPOINTS.m})`),
  window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isM = mql[0].matches;

  document
    .querySelectorAll('.stage-slider')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.stage-slider__item');
      if ($items && ($items.length <= 1) && isM) {
        return;
      }

      componentStore.push(new Slider($slider, {
        sliderFrame: 'stage-slider__block',
        slidesContainer: 'stage-slider__items',
        sliderSlide: 'stage-slider__item',
        sliderControlsframe: 'stage-slider__controls',
        slidesToScroll: 1,
        slidesFocusable: false,
      }));
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

onMediaQueryChange();
