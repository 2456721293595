import { BREAKPOINTS } from 'javascripts/constants';
import throttle from 'javascripts/utils/throttle';

export default class HeaderSticky {
  constructor($panel) {
    this.$page = document.querySelector('.page');
    this.$header = document.querySelector('.header');
    this.$headerInner = this.$header.querySelector('.header__inner');
    this.$headerNavigation = this.$header.querySelector('.header__navigation');
    this.$navigation = document.querySelector('.navigation');
    this.$navigationMain = document.querySelector('.navigation__main');
    this.$navigationService = document.querySelector('.navigation__service');
    this.$navigationSearch = document.querySelector('.navigation__search');
    this.$navigationMeta = document.querySelector('.navigation__meta');
    this.$panel = $panel;
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

    this.mobileInitialized = false;

    // Is the main navigation a jumplist?
    if (this.$navigationMain) {
      this.navigationIsJumplist = this.$navigationMain.classList.contains('navigation__main--jumplist');
    }

    const scroll = window.scrollY || window.pageYOffset;

    // Calculate sticky-header change point
    const headerRect = this.$header.getBoundingClientRect();
    const headerTopPos = Math.round(headerRect.top + scroll);
    this.headerChange = headerTopPos;

    // Calculate main navigation change point (only from XL)
    if (this.$navigationMain) {
      const mainnavRect = this.$navigationMain.getBoundingClientRect();
      const mainnavBottomPos = Math.round(mainnavRect.height);
      this.mainnavChange = mainnavBottomPos;
    }

    // Binded events
    this.bindedScroll = throttle(this.scroll.bind(this), 100);

    // Add events
    document.addEventListener('scroll', this.bindedScroll, { passive: true });

    this.init();
  }

  init() {
    if (this.navigationIsJumplist) {
      this.$header.classList.add('header--navigation-jumplist');
    }

    this.$header.classList.remove('header--navigation-overlay');
    this.$header.classList.remove('header--sticky');

    if (!this.mql.matches) {
      this.$header.classList.add('header--navigation-overlay');
    }

    // Check for initial scrollposition
    this.scroll();
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('scroll', this.bindedScroll);
  }

  mobileServiceNav() {
    const serviceElement = this.$navigationService;
    this.$navigationMeta.parentNode.insertBefore(serviceElement, this.$navigationMeta);

    const searchElement = this.$navigationSearch;
    if (this.$navigationMain) {
      this.$navigationMain.parentNode.insertBefore(searchElement, this.$navigationMain);
    }

    this.mobileInitialized = true;
  }

  scroll() {
    const scroll = window.scrollY || window.pageYOffset;

    if (scroll >= this.headerChange) {
      this.$header.classList.add('header--sticky');

      if (this.mql.matches) {
        this.$page.setAttribute('style', 'padding-top: 14rem;');

        if (scroll >= this.mainnavChange) {
          this.$header.classList.add('header--navigation-overlay');
        } else {
          this.$header.classList.remove('header--navigation-overlay');
        }
      } else {
        this.$page.setAttribute('style', 'padding-top: 9rem;');
        if (!this.mobileInitialized) {
          this.mobileServiceNav();
        }
      }
    } else if (scroll < this.headerChange) {
      this.$header.classList.remove('header--sticky');
      this.$page.removeAttribute('style');

      if (this.mql.matches) {
        if (scroll < this.mainnavChange) {
          this.$header.classList.remove('header--navigation-overlay');
        }
      } else if (!this.mobileInitialized) {
        this.mobileServiceNav();
      }
    }
  }
}
