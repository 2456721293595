import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.js-toggle-accordion').forEach(($toggle) => {
  $toggle.addEventListener('click', (event) => {
    const $target = event.currentTarget;
    const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);
    let offsetTop = 180;

    if (mql.matches) {
      offsetTop = 180;
    } else {
      offsetTop = 130;
    }

    if ($target) {
      const $accordionPanel = $target.closest('.accordion__item');
      const accordionContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!accordionContentVisible).toString());

      const contentClass = $content.classList[0];
      // Switch content visiblity
      if (accordionContentVisible) {
        $content.classList.remove(`${contentClass}--visible`);
        $accordionPanel.classList.remove('accordion__item--open');
      } else {
        $content.classList.add(`${contentClass}--visible`);
        $accordionPanel.classList.add('accordion__item--open');

        const y = $accordionPanel.getBoundingClientRect().top + window.pageYOffset - offsetTop + 10;
        window.scrollTo(0, y);
      }
    }
  });
});
