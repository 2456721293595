import throttle from 'javascripts/utils/throttle';

class ListSearch {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$form = this.$el.querySelector('.list__form');
    this.$filterGroup = this.$el.querySelector('.list-filter-group');

    if (this.$filterGroup) {
      this.initListFilterGroup();
    }
  }

  initListFilterGroup() {
    this.$filterGroup.querySelectorAll('.form-group__item').forEach(($el) => {
      $el.addEventListener('click', () => {
        this.listFormSubmit();
      });
    });
  }

  listFormSubmit() {
    this.$form.submit();
  }
}

class ListFilter {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$filterInput = this.$el.querySelector('.form-search__input');
    this.$targetElements = this.$el.querySelectorAll('.list__list-item');

    if (!this.$filterInput || !this.$targetElements) {
      return;
    }

    // Add keyup event
    this.keyupBinded = throttle(this.keyup.bind(this), 500);
    this.$filterInput.addEventListener('keyup', this.keyupBinded);
  }

  keyup() {
    const searchQuery = this.$filterInput.value;

    if (searchQuery.length > 2) {
      this.filterListItems(searchQuery);
    } else {
      this.resetListItems();
    }
  }

  filterListItems(query) {
    this.$targetElements.forEach(($el) => {
      const $headline = $el.querySelector('.list-item__headline');
      const headlineText = $headline.innerText.toLowerCase();

      const $content = $el.querySelector('.list-item__text');
      const contentText = $content.innerText.toLowerCase();

      const text = `${headlineText} ${contentText}`;

      if (text.includes(query.toLowerCase())) {
        $el.classList.remove('list__list-item--hidden', 'u-hidden-visually');

        const regex = new RegExp(`(${query.toLowerCase()})(?![^<]*>)`, 'ig');
        if (text.match(regex)) {
          $headline.innerHTML = $headline.innerHTML.replace(/<span[^>]+\?>/i, '').replace(/<\/span>/i, '').replace(/<[//]{0,1}(SPAN|span)[^><]*>/g, '');
          $headline.innerHTML = $headline.innerHTML.replace(regex, '<span class="f-highlight">$1</span>');

          $content.innerHTML = $content.innerHTML.replace(/<span[^>]+\?>/i, '').replace(/<\/span>/i, '').replace(/<[//]{0,1}(SPAN|span)[^><]*>/g, '');
          $content.innerHTML = $content.innerHTML.replace(regex, '<span class="f-highlight">$1</span>');
        }
      } else {
        $el.classList.add('list__list-item--hidden', 'u-hidden-visually');
      }
    });
  }

  resetListItems() {
    this.$targetElements.forEach(($el) => {
      $el.classList.remove('list__list-item--hidden', 'u-hidden-visually');

      const $headline = $el.querySelector('.list-item__headline');
      $headline.innerHTML = $headline.innerHTML.replace(/<span[^>]+\?>/i, '').replace(/<\/span>/i, '').replace(/<[//]{0,1}(SPAN|span)[^><]*>/g, '');
      const $content = $el.querySelector('.list-item__text');
      $content.innerHTML = $content.innerHTML.replace(/<span[^>]+\?>/i, '').replace(/<\/span>/i, '').replace(/<[//]{0,1}(SPAN|span)[^><]*>/g, '');
    });
  }
}

document.querySelectorAll('.js-list-filter').forEach($el => new ListFilter($el));
document.querySelectorAll('.js-list-search').forEach($el => new ListSearch($el));
