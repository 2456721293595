import Slider from 'javascripts/globals/slider';

class NewsBanner {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$closeBanner = this.$el.querySelector('.newsbanner__close');
    this.$getDateTime = this.$el.getAttribute('data-date-time');
    this.$unixDateTime = Date.parse(this.$getDateTime);
    this.$items = this.$el.querySelectorAll('.newsbanner__item');

    this.getCookie();
    this.newsbannerSlider();

    this.$el.classList.add('newsbanner--initialized');

    // Add events
    if (this.$closeBanner) {
      this.$closeBanner.addEventListener('click', () => {
        this.Setcookie('gem_newsbanner_closed');
        this.$el.classList.add('newsbanner--hide');
      });
    }
  }

  newsbannerSlider() {
    if (this.$items.length > 1) {
      this.$slider = new Slider(this.$el, {
        sliderFrame: 'newsbanner__block',
        slidesContainer: 'newsbanner__items',
        sliderSlide: 'newsbanner__item',
        sliderControlsframe: 'newsbanner__controls',
        slidesToScroll: 1,
        slidesFocusable: false,
        autoplay: true,
        autoplayTime: 6000,
      });
    }
  }

  getCookie() {
    const bannerCookie = 'gem_newsbanner_closed';
    let bannerName = '';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf('gem_newsbanner_closed') === 0) {
        bannerName = c.substring(bannerCookie.length, c.length);
      }
    }
    if (this.$closeBanner) {
      if (bannerName === '') {
        this.$el.classList.remove('newsbanner--hide');
        const result1 = bannerName;
        const result2 = result1.substring(1);
        if (this.$unixDateTime < result2) {
          this.$el.classList.add('newsbanner--hide');
        }
      } else {
        const result1 = bannerName;
        const result2 = result1.substring(1);
        if (this.$unixDateTime > result2) {
          document.cookie = 'gem_newsbanner_closed= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
          this.$el.classList.remove('newsbanner--hide');
        } else {
          this.$el.classList.add('newsbanner--hide');
        }
      }
    }
  }

  Setcookie(cname) {
    const days = 30;
    const myDate = new Date();
    const cookieValue = this.$unixDateTime;
    myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));

    if (this.$closeBanner) {
      document.cookie = cname + '='.concat(cookieValue).concat(';expires=').concat(myDate.toGMTString());
    }
  }
}

document.querySelectorAll('.newsbanner').forEach($el => new NewsBanner($el));
