import h from 'hyperscript';
import icon from 'components/_particles/icon/generate-icon';
import getVideoId from 'get-video-id';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';

// PhotoSwipe template
const $photoswipeTemplate = h('.pswp', {
  attrs: {
    tabindex: '-1',
    role: 'dialog',
    'aria-hidden': 'true',
  },
}, h('.pswp__bg'), h('.pswp__scroll-wrap#pswp',
  h('.pswp__container',
    h('.pswp__item'),
    h('.pswp__item'),
    h('.pswp__item')),
  h('.pswp__ui.pswp__ui--hidden',
    h('.pswp__top-bar',
      h('.pswp__counter'),
      h('button.pswp__button.pswp__button--close', { title: 'Schließen' },
        icon({ icon: 'close' }),
        h('span.pswp__button-title', 'Schließen')),
      h('.pswp__preloader',
        h('.pswp__preloader__icn',
          h('.pswp__preloader__cut',
            h('.pswp__preloader__donut'))))),
    h('button.pswp__button.pswp__button--arrow--left', { title: 'Vorheriges Bild (Pfeil links)' },
      icon({ icon: 'arrow-left' }),
      h('span.u-hidden-visually', 'Vorheriges Bild')),
    h('button.pswp__button.pswp__button--arrow--right', { title: 'Nächstes Bild (Pfeil rechts)' },
      icon({ icon: 'arrow-right' }),
      h('span.u-hidden-visually', 'Nächstes Bild')),
    h('.pswp__caption',
      h('.pswp__caption__center'))), h('.pswp__video-thumb-wrapper', h('#pswp__video-thumb.pswp__video-thumb-wrapper-inner', h('#pswp__thumb.pswp__thumbs-wrapper', h('.pswp__thumbs-title', 'Weitere Videos'), h('.pswp__thumbs-list'))))));

// Create array of gallery items
const createGalleryItems = $thumbs => $thumbs.map(
  ($thumb) => {
    // Get image size
    const $figure = $thumb.closest('.figure');
    const size = $thumb.getAttribute('data-size').split('x');

    // Check for caption/copyright
    let title;
    let $iframe;
    let $teaserContent;
    const $caption = $figure ? $figure.querySelector('.figure__caption') : null;
    const $copyright = $figure ? $figure.querySelector('.tooltip__text') : null;
    const $teaserVideo = $figure ? $figure.parentNode.parentNode : null;

    if ($teaserVideo.classList.contains('teaser--video')) {
      const $teaserTitle = $teaserVideo.querySelector('.headline');
      const $teaserText = $teaserVideo.querySelector('.teaser__text p');

      $teaserContent = h('.lightbox-content');
      if ($teaserTitle) {
        $teaserContent.appendChild(h('h3.lightbox-headline.headline--3', `${$teaserTitle.textContent}`));
      }

      if ($teaserText) {
        $teaserContent.appendChild(h('.lightbox-text', `${$teaserText.textContent}`));
      }
    }

    if ($teaserVideo.classList.contains('teaser--video')) {
      const { id, service } = getVideoId($thumb.getAttribute('data-video'));

      if (id && (service === 'youtube' || service === 'vimeo')) {
        let videoSrc;

        if (service === 'youtube') {
          videoSrc = `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=0`;
        } else if (service === 'vimeo') {
          videoSrc = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`;
        }

        $iframe = h('.pswp__video-wrapper',
          h('.pswp__video-wrapper-inner',
            h('iframe.pswp__video', {
              // eslint-disable-next-line no-mixed-operators, no-undef
              src: videoSrc || '',
              allowFullscreen: true,
              msAllowFullscreen: true,
              webkitAllowFullscreen: true,
              mozAllowFullscreen: true,
              autostart: false,
            }),
            $teaserContent));
      }
      $thumb.appendChild($iframe);
    }

    if ($teaserContent) {
      title = $teaserContent;
    } else if ($caption && !$copyright) {
      title = `<span class='text'>${$caption.textContent}</span>`;
    } else if ($caption && $copyright) {
      title = `<span class='text'>${$caption.textContent}</span>`;
    }

    return {
      src: $thumb.getAttribute('href'),
      w: parseInt(size[0], 10),
      h: parseInt(size[1], 10),
      title,
      $iframe,
      $teaserContent,
    };
  },
);

// Attach photoswipe to items
const attachPhotoswipe = ($thumbs, galleryItems) => $thumbs.forEach(
  ($thumb, index) => {
    const $figure = $thumb.closest('.figure');
    const $teaserVideo = $figure ? $figure.parentNode.parentNode : null;
    const $dataVideo = $thumb.querySelector('.pswp__video-wrapper');
    const $dataCap = $thumb.querySelector('.lightbox-content');

    // console.log($thumb);
    $thumb.addEventListener('click', (event) => {
      event.preventDefault();

      // Insert template
      const $template = $photoswipeTemplate.cloneNode(true);
      document.body.appendChild($template);
      const $thumbsList = document.querySelector('.pswp__thumbs-list');

      if ($teaserVideo.classList.contains('teaser--video')) {
        document.getElementById('pswp').classList.add('pswp--video');
        document.getElementById('pswp__video-thumb').appendChild($dataVideo);
        $dataVideo.appendChild($dataCap);
      }

      // Init PhotoSwipe
      const slideshow = new PhotoSwipe(
        $template,
        PhotoSwipeUiDefault,
        galleryItems,
        {
          index,
          barsSize: { top: 100, bottom: 'auto' },
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          mouseUsed: true,
        },
      );

      // After gallery is closed, destroy DOM
      slideshow.listen('destroy', () => $template.remove());

      $thumbs.forEach(
        ($thumbInner) => {
          if ($teaserVideo.classList.contains('teaser--video')) {
            const $dataThumbIcon = $thumbInner.parentNode.parentNode.querySelector('.icon-button');
            const $thumbHeadline = h('p.pswp__thumb-text', $thumbInner.parentNode.parentNode.parentNode.querySelector('.headline').innerText);
            const $dataThumbImage = $thumbInner.querySelector('.image__img');
            const $thumbIcon = $dataThumbIcon.cloneNode(true);
            const $thumbImage = h('.pswp__thumb-image', $dataThumbImage.cloneNode(true), $thumbIcon);
            $thumbsList.appendChild(h('.pswp__thumb', $thumbImage, $thumbHeadline));
          }
        },
      );

      const $thumbItem = $thumbsList.querySelectorAll('.pswp__thumb');

      $thumbItem.forEach(
        ($thumbItemInner, $thumbIndex) => {
          $thumbItemInner.addEventListener('click', (e) => {
            e.preventDefault();
            slideshow.goTo($thumbIndex);
          });
        },
      );


      // Start gallery
      slideshow.init();

      // After gallery is closed, destroy DOM
      slideshow.listen('afterChange', () => {
        const removeChild = $template.querySelector('.pswp__video-wrapper');
        if ($teaserVideo.classList.contains('teaser--video')) {
          let $currentIframe;
          const $currentItem = slideshow.currItem.$iframe.querySelector('.pswp__video').getAttribute('src') || slideshow.currItem.$iframe.querySelector('.pswp__video').getAttribute('data-src');
          const $currentCaption = slideshow.currItem.$teaserContent;
          const { id, service } = getVideoId($currentItem);

          if (id && (service === 'youtube' || service === 'vimeo')) {
            let videoSrc;

            if (service === 'youtube') {
              videoSrc = `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=0`;
            } else if (service === 'vimeo') {
              videoSrc = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`;
            }

            $currentIframe = h('.pswp__video-wrapper',
              h('.pswp__video-wrapper-inner',
                h('iframe.pswp__video', {
                  // eslint-disable-next-line no-mixed-operators, no-undef
                  src: videoSrc || '',
                  allowFullscreen: true,
                  msAllowFullscreen: true,
                  webkitAllowFullscreen: true,
                  mozAllowFullscreen: true,
                  autostart: false,
                }),
                $currentCaption));
          }
          document.getElementById('pswp__video-thumb').removeChild(removeChild);
          document.getElementById('pswp__video-thumb').appendChild($currentIframe);
        }
      });
    });
  },
);

const initPhotoswipe = ($thumbs) => {
  if (!$thumbs || !$thumbs.length) {
    return;
  }

  attachPhotoswipe($thumbs, createGalleryItems($thumbs));
};

export default initPhotoswipe;
