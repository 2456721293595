import Slider from 'javascripts/globals/slider';
import { BREAKPOINTS } from 'javascripts/constants';

const mql = [
  window.matchMedia(`(min-width: ${BREAKPOINTS.m})`),
  window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isM = mql[0].matches;

  document
    .querySelectorAll('.slider-content__slider')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.slider-content__item');
      if ($items && ($items.length <= 1) && isM) {
        return;
      }

      componentStore.push(new Slider($slider, {
        sliderFrame: 'slider-content__block',
        slidesContainer: 'slider-content__items',
        sliderSlide: 'slider-content__item',
        sliderControlsframe: 'slider-content__controls',
        sliderDotsframe: 'slider__dots',
        slidesToScroll: 1,
        slidesFocusable: false,
      }));

      if (isM) {
        const $dots = $slider.querySelector('.slider__dots');
        $dots.removeChild($dots.lastChild);
      }
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

onMediaQueryChange();
