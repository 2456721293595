document.querySelectorAll('.list-filter-group').forEach(($toggle) => {
  const listFilterGroupHeader = $toggle.querySelector('.list-filter-group__header');
  const mql = [
    window.matchMedia('(max-width: 1259px)'),
  ];

  const isXL = mql[0].matches;

  function onMediaQueryUpdate() {
    listFilterGroupHeader.addEventListener('click', (event) => {
      const $target = event.currentTarget;

      if ($target) {
        const listFilterGroupVisible = $toggle.getAttribute('aria-expanded') === 'true';

        // Switch aria state
        $toggle.setAttribute('aria-expanded', (!listFilterGroupVisible).toString());

        // Switch content visiblity
        if (listFilterGroupVisible) {
          $toggle.classList.remove('list-filter-group--open');
        } else {
          $toggle.classList.add('list-filter-group--open');
        }
      }
    });
  }

  if (isXL) {
    onMediaQueryUpdate();
  }

  mql.forEach(((el) => {
    el.addListener(onMediaQueryUpdate);
  }));
});
