import getTarget from 'javascripts/utils/get-target';

// Tab bar
export default class TabBar {
  constructor($tabBar) {
    // Get references
    this.$tabBar = $tabBar;
    this.$items = this.$tabBar.querySelector('.glossary__tab-bar__items');
    this.items = this.$items.querySelectorAll('.glossary__tab-bar__item');
    this.$glossaryPanel = $tabBar;
    this.$glossaryPanel.addEventListener('selectTab', this.onSelectTab.bind(this));
    this.$glossaryPanel.classList.add('glossary--initialized');

    // Register events
    this.$items.addEventListener('click', this.onItem.bind(this));
    window.addEventListener('hashchange', this.onHashchange.bind(this));
    this.$tabBar.addEventListener('resendEvents', this.onResendEvents.bind(this));

    // Select tab per URL parameter
    this.onHashchange();
  }

  onSelectTab(event) {
    // Reset all panels
    const $panels = this.$glossaryPanel.querySelectorAll('.glossary__panel');
    $panels.forEach(($panel) => {
      $panel.removeAttribute('tabindex');
      $panel.setAttribute('hidden', true);
    });

    // Get href
    const target = event.target.getAttribute('href');

    // Find new panel
    const $panel = this.$glossaryPanel.querySelector(target);

    if ($panel) {
      // Make panel visible
      $panel.removeAttribute('hidden');
      $panel.focus();
    }
  }

  focusNext($currentItem, $startItem) {
    // Determine which item is the startItem (first or last)
    const down = $startItem === this.items[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextThing = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextThing && $nextThing.firstElementChild) || $startItem;
    };

    // Move and focus
    const $nextItem = move($currentItem);
    $nextItem.focus();
  }

  openByHash() {
    if (window.location.hash) {
      // Extract search term and first letter of search term
      const searchTerm = window.location.hash.substring(1);
      const searchTermInitial = searchTerm[0].toUpperCase();
      // Find corresponding glossary panel
      const $target = document.getElementById(`glossary-tab-${searchTermInitial}`);

      if ($target && $target.matches('.glossary__tab-bar__item')) {
        // Open the corresponding glossary panel by first letter of hash
        $target.click();

        const $targetPanelId = $target.getAttribute('href').substring(1);
        const $targetPanel = document.getElementById($targetPanelId);
        const $targetPanelItems = $targetPanel.querySelectorAll('.accordion__item');

        $targetPanelItems.forEach(($item) => {
          // Reset all accordion items to closed state
          $item.classList.remove('accordion__item--open');
          $item.querySelector('.accordion__toggle').setAttribute('aria-expanded', 'false');
          $item.querySelector('.accordion__content').classList.remove('accordion__content--visible');

          // Compare full hash with label's text-content.
          // If found trigger a click which opens up the accordion item
          if ($item.querySelector('.accordion__toggle-label').textContent.toLowerCase() === decodeURI(searchTerm).toLowerCase()) {
            $item.querySelector('.accordion__toggle').click();
          }
        });
        return true;
      }
    }

    return false;
  }

  onHashchange() {
    this.openByHash();
  }

  onResendEvents() {
    if (!this.openByHash()) {
      const $item = this.$items.querySelector('.glossary__tab-bar__item[aria-selected="true"]');
      if ($item) {
        this.onItem({
          target: $item,
          preventDefault: () => { },
        }, true);
      }
    }
  }

  onItem(event, repeated = false) {
    const $target = getTarget(event.target, '.glossary__tab-bar__item');

    if ($target) {
      // If target is on the same page, prevent navigation
      if (this.isSamePage($target)) {
        event.preventDefault();
      }

      // Remove old active item
      this.items.forEach(($item) => {
        $item.classList.remove('glossary__tab-bar__item--active');
        $item.removeAttribute('aria-selected');
        $item.removeAttribute('aria-checked');
      });

      // Set new active
      $target.classList.add('glossary__tab-bar__item--active');

      // Send event
      const selectTabEvent = new CustomEvent('selectTab', {
        bubbles: true,
        detail: {
          repeated,
        },
      });
      $target.dispatchEvent(selectTabEvent);
    }
  }

  onItemKeydown(event) {
    const $target = getTarget(event.target, '.glossary__tab-bar__item');

    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      this.focusNext($target, this.items[0]);
    }

    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      this.focusNext($target, this.items[this.items.length - 1]);
    }
  }

  isSamePage($link) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }
}

// Init
document.querySelectorAll('.glossary').forEach($tabBar => new TabBar($tabBar));
