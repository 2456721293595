import { BREAKPOINTS } from 'javascripts/constants';
import NavigationJumplist from './navigation-jumplist';

document.querySelectorAll('.navigation').forEach(() => {
  // Init media query
  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;
    const $navigationMain = document.querySelector('.navigation__main');
    const $content = document.getElementById('content');

    if (isDesktop && $navigationMain) {
      const navigationMainHeight = Math.ceil($navigationMain.getBoundingClientRect().height + 45);
      const contentHeight = Math.ceil($content.getBoundingClientRect().height);

      if (contentHeight < navigationMainHeight) {
        $content.style.minHeight = `${navigationMainHeight}px`;
      }
    } else if ($content) {
      $content.removeAttribute('style');
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});

if (document.querySelector('.navigation__main--jumplist')) {
  // eslint-disable-next-line no-unused-vars
  const navigationJumplist = new NavigationJumplist(document.querySelector('.navigation__main--jumplist'));
  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  // Listener
  const onMediaQueryChange = () => {
    navigationJumplist.init();
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
}
