import { BREAKPOINTS } from 'javascripts/constants';

export default class NavigationJumplist {
  constructor($jumplist) {
    this.$jumplist = $jumplist;
    this.$jumpLinks = this.$jumplist.querySelectorAll('.navigation__main-sublink');
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);
    this.offsetTop = 180;

    this.bindedJumplinkClick = this.jumplinkClick.bind(this);

    this.$jumpLinks.forEach(($jumplink) => {
      $jumplink.addEventListener('click', this.bindedJumplinkClick);
    });
  }

  init() {
    if (this.mql.matches) {
      this.offsetTop = 180;
    } else {
      this.offsetTop = 130;
    }

    if (window.location.hash) {
      const currentHash = window.location.hash;
      window.location.hash = '';

      setTimeout(() => {
        window.scrollTo(0, 0);
        this.jumpToPosition(currentHash);
      }, 1);
    }
  }

  jumpToPosition(hash) {
    const $linkTargetEl = document.getElementById(hash.substring(1));
    const linkTargetRect = $linkTargetEl.getBoundingClientRect();
    const scroll = window.scrollY || window.pageYOffset;
    const linkTargetStyle = $linkTargetEl.currentStyle || window.getComputedStyle($linkTargetEl);
    // eslint-disable-next-line
    const scrollToPosition = linkTargetRect.top + scroll - (this.offsetTop - parseInt(linkTargetStyle.paddingTop, 10));
    window.scroll(0, scrollToPosition);

    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, '', hash);
  }

  jumplinkClick(event) {
    event.preventDefault();

    let linkEl;
    if (event.target.classList.contains('navigation__main-sublink')) {
      linkEl = event.target;
    } else {
      linkEl = event.target.closest('.navigation__main-sublink');
    }

    const linkHash = linkEl.hash;
    this.jumpToPosition(linkHash);
  }
}
