import h from 'hyperscript';
import getVideoId from 'get-video-id';
import initPhotoswipe from '../../../javascripts/utils/photoswipe';

class Figure {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$media = this.$el.querySelector('.figure__media');
    this.$videoPlay = this.$el.querySelector('.figure__play');

    // Add events
    if (this.$videoPlay) {
      this.$videoPlay.addEventListener('click', this.onVideoClick.bind(this));
    }
  }

  onVideoClick(event) {
    event.preventDefault();

    const { id, service } = getVideoId(this.$videoPlay.href);

    if (id && (service === 'youtube' || service === 'vimeo')) {
      let videoSrc;

      if (service === 'youtube') {
        videoSrc = `https://www.youtube.com/embed/${id}?rel=0&autoplay=0`;
      } else if (service === 'vimeo') {
        videoSrc = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0`;
      }

      // Construct Youtube iframe player, add rel=0 to disable related videos
      const $iframe = h(
        '.figure__video-wrapper',
        h('iframe.figure__video', {
          src: videoSrc,
          allowFullscreen: true,
          msAllowFullscreen: true,
          webkitAllowFullscreen: true,
          mozAllowFullscreen: true,
        }),
      );

      // Replace with iframe
      this.$videoPlay.parentNode.replaceChild($iframe, this.$videoPlay);
    }
  }

  getMediaType() {
    return this.$el.querySelector('.figure__play') ? 'video' : 'image';
  }
}

document.querySelectorAll('.js-figure').forEach($el => new Figure($el));

// lightbox
initPhotoswipe(Array.prototype.slice.call(document.querySelectorAll('.js-lightbox')));
