import Tablesort from 'tablesort';

export default class Table {
  constructor($tableContainer) {
    this.$el = $tableContainer;
    this.$table = this.$el.querySelector('table');
    this.$tooltips = this.$el.querySelectorAll('.tooltip__popup');
    this.$downloadCheckboxes = this.$el.querySelectorAll('.table__download-checkbox');

    if (this.$tooltips.length) {
      this.initTableTooltips();
    }

    if (this.$downloadCheckboxes.length) {
      this.initTableDownloadCheckboxes();
    }

    if (this.$el.classList.contains('table--sortable') && this.$table) {
      this.initTableSortable();
    }
  }

  initTableSortable() {
    Tablesort(this.$table);
  }

  initTableTooltips() {
    this.$tooltips.forEach(($tooltip) => {
      this.$el.append($tooltip);
    });
  }

  initTableDownloadCheckboxes() {
    this.onDownloadCheckboxClickBinded = this.onDownloadCheckboxClick.bind(this);

    this.$downloadCheckboxes.forEach(($checkbox) => {
      $checkbox.addEventListener('click', this.onDownloadCheckboxClickBinded);
    });
  }

  onDownloadCheckboxClick() {
    let checked = 0;

    this.$downloadCheckboxes.forEach(($checkbox) => {
      if ($checkbox.checked) {
        checked += 1;
      }
    });

    if (checked > 0) {
      document.querySelector('.table__button--download-selected').style.display = 'inline-flex';
    } else {
      document.querySelector('.table__button--download-selected').style.display = 'none';
    }
  }
}

document.querySelectorAll('.table').forEach($tableContainer => new Table($tableContainer));
