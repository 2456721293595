import { createFocusTrap } from 'focus-trap';

export default class ToggleHeaderSearch {
  constructor($toggleButton) {
    this.$toggleButton = $toggleButton;
    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$closeButton = this.$panel.querySelector('.navigation__search-close');

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedOutsideClick = this.outsideClick.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Init focus trap
    this.focusTrap = createFocusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });
  }


  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  outsideClick(event) {
    if (event.target.closest('.navigation__search') || event.target.closest('#header-search-toggle')) return;

    this.closePanel();
  }

  closePanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Close Search Panel
    this.$panel.classList.remove('navigation__search--open');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    document.body.removeEventListener('click', this.bindedOutsideClick);
    this.$closeButton.removeEventListener('click', this.bindedCloseClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }
  }

  openPanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Show search panel
    this.$panel.classList.add('navigation__search--open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    document.body.addEventListener('click', this.bindedOutsideClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Enable focus trap
    this.focusTrap.activate();
  }
}
