import h from 'hyperscript';
import classNames from 'classnames';
import randomId from 'javascripts/utils/random-id';
import label from '../../atoms/label/label';
import select from '../../atoms/select/select';

export default function filterSelect({
  tag,
  id = false,
  labelText = false,
  classes = false,
  name = '',
  disabled = false,
  invalid = false,
  required = false,
  descriptionId = false,
  labelId = false,
  errorId = false,
  hasPleaseSelectOption = true,
  pleaseSelectOptionText,
  options = false,
}) {
  const defId = id || `filter-select-${randomId()}`;

  const $label = label({
    labelFor: defId,
    text: labelText,
    disabled,
    classes: 'filter-select__label',
  });

  const $select = h('.filter-select__input', select({
    id: defId,
    name,
    disabled,
    required,
    invalid,
    descriptionId,
    labelId,
    errorId,
    classes: 'filter-select__select',
    hasPleaseSelectOption,
    pleaseSelectOptionText,
    options,
  }));

  const $filterSelectField = h('.filter__field');

  const $filterSelect = h(tag || 'div', {
    className: classNames(
      'filter-select',
      classes && classes,
    ),
  });

  if ($label) $filterSelect.appendChild($label);
  if ($select) $filterSelect.appendChild($select);

  $filterSelectField.appendChild($filterSelect);

  return $filterSelectField;
}
