export default class GlossaryContent {
  constructor($glossaryContent) {
    this.$glossaryContent = $glossaryContent;
    this.$glossaryContent.addEventListener('selectTab', this.onSelectTab.bind(this));

    this.$glossaryContent.classList.add('glossary-content--initialized');
  }

  onSelectTab(event) {
    // Get current panel
    const $currentPanel = this.$glossaryContent.querySelector('.glossary-content__panel:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = this.$glossaryContent.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.glossary-content__panel');

    // Make panel visible
    $panel.removeAttribute('hidden');
    $headline.focus();
  }
}

document.querySelectorAll('.glossary-content').forEach($el => new GlossaryContent($el));
