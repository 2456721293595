import h from 'hyperscript';
import { checkVAT, countries } from 'jsvat';
import randomId from 'javascripts/utils/random-id';

export default class InputUstid {
  constructor($el, options) {
    this.$el = $el;
    this.options = {
      class: 'input-ustid',
      invalidModifier: 'input-ustid--invalid',
      validModifier: 'input-ustid--valid',
      inputClass: 'input-ustid__input',
      selectClass: 'input-ustid__select',
      errorClass: 'input-ustid__error',
      errorMessage: 'Invalid or non-existing VAT-ID',
      selectedCountry: 'DE',
      ...options,
    };

    this.init();
  }

  init() {
    // Check for nesseccary elements
    // Check for the actual vat input element
    this.$vatInput = this.$el.querySelector(`.${this.options.inputClass}`);
    if (this.$vatInput) {
      // Find component id or set it
      this.id = this.$vatInput.id || `${this.options.class}-${randomId()}`;
      this.$vatInput.id = this.id;

      // Set event listeners
      // Input validation on blur, focus and on paste
      this.$vatInput.addEventListener('blur', () => this.vatInputChanged());
      this.$vatInput.addEventListener('focus', () => this.vatInputChanged());
      this.$vatInput.addEventListener('paste', (event) => {
        event.preventDefault();
        this.$vatInput.value = event.clipboardData.getData('text/plain');
        this.vatInputChanged();
      });
    }

    // Find country code selector
    this.$countrySelect = this.$el.querySelector(`.${this.options.selectClass}`);
    // TODO: Fill selector with countrie codes....

    if (this.$countrySelect) {
      // Fill select with country codes
      this.$countrySelect.innerHTML = countries.map(
        country => h('option', {}, country.codes[0]).outerHTML,
      ).sort().join('');
      // Set default selected value
      this.$countrySelect.value = this.options.selectedCountry;
      // Input validation on country code change
      this.$countrySelect.addEventListener('change', () => this.countrySelectChanged());
    }

    // Find error message element
    this.$errormessage = this.$el.querySelector(`.${this.options.errorClass}`);
    // If not present, generate one.
    if (!this.$errormessage) {
      this.$errormessage = h(
        `p#${this.id}-error.${this.options.errorClass}`, {},
        this.options.errorMessage,
      );
    }

    this.vatInputChanged();
  }

  countrySelectChanged() {
    if (this.$vatInput.value.length > 0) {
      this.sanitize();
      this.validate();
    } else {
      this.resetState();
    }
  }

  vatInputChanged() {
    if (this.$vatInput.value.length > 0) {
      this.sanitize();
      this.validate();
    } else {
      this.resetState();
    }
  }

  // Clean up method to transform the user input into the format requested by jsvat.
  sanitize() {
    const sanitizeChars = [' ', '.', '-', '_', ',', ';', ':', '*', '&', '%', '$', '#', '@', '!'];
    let vatValue = this.$vatInput.value;
    vatValue = vatValue.trim(); // Remove whitespace from both ends of string
    this.$vatInput.value = vatValue; // Return trimmed vatValue to the input field

    const countryCode = vatValue.substr(0, 2);
    const codes = countries.map(country => country.codes[0]);
    const hasCountry = codes.findIndex(code => code === countryCode) >= 0;

    if (hasCountry) {
      this.$countrySelect.value = countryCode;
    } else {
      const countryCodeSelect = this.$countrySelect.value;
      vatValue = countryCodeSelect + vatValue;
    }

    sanitizeChars.forEach(
      (char) => {
        vatValue = vatValue.split(char).join('');
      },
    );

    this.vatValueSanitized = vatValue;
  }

  validate() {
    const result = checkVAT(this.vatValueSanitized, countries);
    const valid = result.isValid && result.isValidFormat;

    if (valid) {
      this.$el.classList.remove(this.options.invalidModifier);
      this.$el.classList.add(this.options.validModifier);
      this.$vatInput.removeAttribute('aria-invalid');
      this.$vatInput.removeAttribute('aria-errormessage');
      this.$countrySelect.removeAttribute('aria-invalid');

      this.$vatInput.value = this.vatValueSanitized;

      if (this.$errormessage.isConnected) {
        this.$el.removeChild(this.$errormessage);
      }
    } else {
      this.$el.classList.add(this.options.invalidModifier);
      this.$el.classList.remove(this.options.validModifier);
      this.$vatInput.setAttribute('aria-invalid', 'true');
      this.$vatInput.setAttribute('aria-errormessage', `${this.id}-error`);
      this.$countrySelect.setAttribute('aria-invalid', 'true');
      this.$el.appendChild(this.$errormessage);
      this.$errormessage.innerHTML = `${this.options.errorMessage} for ${result.country.name}`;
    }
  }

  resetState() {
    this.$el.classList.remove(this.options.invalidModifier);
    this.$el.classList.remove(this.options.validModifier);
    this.$vatInput.removeAttribute('aria-invalid');
    this.$vatInput.removeAttribute('aria-errormessage');
    this.$countrySelect.removeAttribute('aria-invalid');

    if (this.$errormessage.isConnected) {
      this.$el.removeChild(this.$errormessage);
    }
  }
}

document.querySelectorAll('.input-ustid').forEach(
  $inputUstId => new InputUstid($inputUstId),
);
