import ResizeObserver from 'resize-observer-polyfill';
import CustomEvent from 'custom-event';
import getTarget from 'javascripts/utils/get-target';
import { MEDIA_QUERIES } from 'javascripts/constants';

// Tab bar
export default class TabBar {
  constructor($tabBar, resizeObserver) {
    // Get references
    this.$tabBar = $tabBar;
    this.$items = this.$tabBar.querySelector('.tab-bar__items');
    this.items = this.$items.querySelectorAll('.tab-bar__item');
    this.$select = this.$tabBar.querySelector('.tab-bar__select');

    // Is section list
    this.isSectionList = this.$tabBar.classList.contains('tab-bar--section');

    // Register with observer
    resizeObserver.observe(this.$tabBar);

    // Outside click
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);

    // Register events
    this.$select.addEventListener('click', this.onSelect.bind(this));
    this.$select.addEventListener('keydown', this.onSelectKeydown.bind(this));
    this.$items.addEventListener('click', this.onItem.bind(this));
    this.$items.addEventListener('keydown', this.onItemKeydown.bind(this));
    window.addEventListener('hashchange', this.onHashchange.bind(this));
    this.$tabBar.addEventListener('resendEvents', this.onResendEvents.bind(this));

    // Select tab per URL parameter
    this.onHashchange();
  }

  static onResize(tabBars) {
    tabBars.forEach((tabBar) => {
      const $tabBar = getTarget(tabBar.target, '.tab-bar');
      const $items = $tabBar.querySelector('.tab-bar__items');

      // Reset back
      $tabBar.classList.remove('tab-bar--menu');
      $tabBar.classList.add('tab-bar--list', 'tab-bar--uninitalized');

      // Get widths and collapse status
      const tabBarWidth = $tabBar.getBoundingClientRect().width;
      let itemsWidth = 1;
      $items.querySelectorAll('.tab-bar__item-wrap').forEach(($item) => {
        itemsWidth += $item.getBoundingClientRect().width - 1;
      });
      const isCollapsed = itemsWidth > tabBarWidth;

      // Switch between list and menu
      TabBar.switchBetweenListAndMenu($tabBar, isCollapsed);
    });
  }

  static switchBetweenListAndMenu($tabBar, isCollapsed) {
    const $items = $tabBar.querySelector('.tab-bar__items');

    // Switch class
    $tabBar.classList[isCollapsed ? 'add' : 'remove']('tab-bar--menu');
    $tabBar.classList[isCollapsed ? 'remove' : 'add']('tab-bar--list');

    // Remove uninitalized class
    $tabBar.classList.remove('tab-bar--uninitalized');

    // Switch role of list container
    $items.setAttribute('role', isCollapsed ? 'menu' : 'tablist');
    $items.hidden = isCollapsed;

    // Update list items
    $items.querySelectorAll('.tab-bar__item').forEach(($item) => {
      // Switch role
      $item.setAttribute('role', isCollapsed ? 'menuitemradio' : 'tab');

      // Update aria props for current active item
      if (isCollapsed && $item.getAttribute('aria-selected') === 'true') {
        $item.removeAttribute('aria-selected');
        $item.setAttribute('aria-checked', 'true');
      } else if (!isCollapsed && $item.getAttribute('aria-checked') === 'true') {
        $item.removeAttribute('aria-checked');
        $item.setAttribute('aria-selected', 'true');
      }

      // Adjust tabindex
      if (isCollapsed) {
        $item.setAttribute('tabindex', '-1');
      } else {
        $item.removeAttribute('tabindex');
      }
    });
  }

  initSectionList() {
    // Remove uninitalized class
    this.$tabBar.classList.remove('tab-bar--uninitalized');

    // Switch between list and menu
    const switchOnMediaQueryChange = (mq) => {
      TabBar.switchBetweenListAndMenu(this.$tabBar, !mq.matches);
    };

    // Init media query
    const mql = window.matchMedia(MEDIA_QUERIES.m);
    mql.addListener(switchOnMediaQueryChange);
    switchOnMediaQueryChange(mql);
  }

  openMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'true');
    this.$items.hidden = false;

    // Get current active item
    const $checked = this.$items.querySelector('[aria-checked="true"]');
    $checked.focus();

    // Catch outside clicks
    document.addEventListener('click', this.onOutsideClickBinded);
  }

  closeMenu() {
    // Set state
    this.$select.setAttribute('aria-expanded', 'false');
    this.$items.hidden = true;
  }

  toggleMenu() {
    const expanded = this.$select.getAttribute('aria-expanded') === 'true';
    return expanded ? this.closeMenu() : this.openMenu();
  }

  focusNext($currentItem, $startItem) {
    // Determine which item is the startItem (first or last)
    const down = $startItem === this.items[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextThing = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextThing && $nextThing.firstElementChild) || $startItem;
    };

    // Move and focus
    const $nextItem = move($currentItem);
    $nextItem.focus();
  }

  openByHash() {
    if (window.location.hash) {
      const $target = document.getElementById(window.location.hash.substring(1));

      if ($target && $target.matches('.tab-bar__item')) {
        $target.click();
        return true;
      }
    }

    return false;
  }

  onOutsideClick(event) {
    if (!this.$items.contains(event.target) && !this.$select.contains(event.target)) {
      document.removeEventListener('click', this.onOutsideClickBinded);
      this.closeMenu();
    }
  }

  onHashchange() {
    this.openByHash();
  }

  onResendEvents() {
    if (!this.openByHash()) {
      const $item = this.$items.querySelector('.tab-bar__item[aria-selected="true"]');
      if ($item) {
        this.onItem({
          target: $item,
          preventDefault: () => { },
        }, true);
      }
    }
  }

  onSelect() {
    this.toggleMenu();
  }

  onSelectKeydown(event) {
    // Also toggle on down arrow
    if (event.keyCode === 40) {
      if (this.$items.hidden) {
        this.openMenu();
      } else {
        this.items[0].focus();
      }
    }

    // close menu on up arrow
    if (event.keyCode === 38) {
      this.closeMenu();
    }
  }

  onItem(event, repeated = false) {
    const $target = getTarget(event.target, '.tab-bar__item');
    const isMenuCurrently = this.$tabBar.classList.contains('tab-bar--menu');

    if ($target) {
      // If target is on the same page, prevent navigation
      if (this.isSamePage($target)) {
        event.preventDefault();
      }

      // Remove old active item
      this.items.forEach(($item) => {
        $item.classList.remove('tab-bar__item--active');
        $item.removeAttribute('aria-selected');
        $item.removeAttribute('aria-checked');
      });

      // Set new active
      $target.setAttribute(isMenuCurrently ? 'aria-checked' : 'aria-selected', 'true');
      $target.classList.add('tab-bar__item--active');

      // Update select text
      const $currentSelect = this.$select.querySelector('.tab-bar__select-current');
      $currentSelect.innerHTML = $target.innerHTML;

      // Close menu
      if (isMenuCurrently) {
        this.closeMenu();
      }

      // Send event
      const selectTabEvent = new CustomEvent('selectTab', {
        bubbles: true,
        detail: {
          repeated,
        },
      });
      $target.dispatchEvent(selectTabEvent);
    }
  }

  onItemKeydown(event) {
    const $target = getTarget(event.target, '.tab-bar__item');
    const isMenuCurrently = this.$tabBar.classList.contains('tab-bar--menu');

    if (!$target || !isMenuCurrently) {
      return;
    }

    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      this.focusNext($target, this.items[0]);
    }

    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      this.focusNext($target, this.items[this.items.length - 1]);
    }

    // Esc / tab
    if (event.keyCode === 27 || event.keyCode === 9) {
      this.toggleMenu();
    }

    // If escape, refocus menu button
    if (event.keyCode === 27) {
      event.preventDefault();
      this.$select.focus();
    }
  }

  isSamePage($link) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }
}

// Init
const observer = new ResizeObserver(TabBar.onResize);
document.querySelectorAll('.tab-bar').forEach($tabBar => new TabBar($tabBar, observer));
