import { BREAKPOINTS } from 'javascripts/constants';
import ToggleButton from './header-toggle';
import ToggleHeaderSearch from './header-search';
import HeaderSticky from './header-sticky';


if (document.getElementById('header-search-toggle')) {
  // eslint-disable-next-line no-unused-vars
  const toggleHeaderSearch = new ToggleHeaderSearch(document.getElementById('header-search-toggle'));
}

// Navigation toggle button
document.querySelectorAll('.header__navigation-toggle-button').forEach(($toggleButton) => {
  const toggleButton = new ToggleButton($toggleButton);
  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  // Listener
  const onMediaQueryChange = () => {
    toggleButton.init();
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});

// Sticky header
document.querySelectorAll('.header__inner').forEach(($panel) => {
  const headerSticky = new HeaderSticky($panel);
  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  // Listener
  const onMediaQueryChange = () => {
    headerSticky.init();
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
