const $trigger = document.body.querySelector('.header__logo');
const $stripesWrapper = document.body.querySelector('.logo__stripes');
const animationDuration = 3000;
const resetDuration = 500;
const initDelay = 500;

const animating = () => {
  setTimeout(() => {
    $stripesWrapper.classList.add('logo__stripes--animation');
    $stripesWrapper.classList.add('logo__stripes--visible');

    setTimeout(() => {
      $stripesWrapper.classList.remove('logo__stripes--animation');

      $trigger.addEventListener('mouseover', () => {
        if (!$stripesWrapper.classList.contains('logo__stripes--animation')) {
          $stripesWrapper.classList.remove('logo__stripes--visible');
          $stripesWrapper.classList.add('logo__stripes--invisible');

          setTimeout(() => {
            $stripesWrapper.classList.remove('logo__stripes--invisible');
            $stripesWrapper.classList.add('logo__stripes--visible');
            $stripesWrapper.classList.add('logo__stripes--animation');

            setTimeout(() => {
              $stripesWrapper.classList.remove('logo__stripes--animation');
            }, animationDuration);
          }, resetDuration);
        }
      });
    }, animationDuration);
  }, initDelay);
};

if ($stripesWrapper) {
  animating();
}
