document.querySelectorAll('.collapsible').forEach(($dropdown) => {
  const $toggle = $dropdown.querySelector('.collapsible__button');

  const toggleDropdown = (event) => {
    const $target = event.currentTarget;
    event.stopPropagation();

    if ($target) {
      const dropdownContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!dropdownContentVisible).toString());

      // Switch content visiblity
      if (dropdownContentVisible) {
        $content.classList.remove('collapsible__content--visible');
      } else {
        $content.classList.add('collapsible__content--visible');
      }
    }
  };

  $dropdown.addEventListener('click', toggleDropdown);
  if ($toggle) {
    $toggle.addEventListener('click', toggleDropdown);
  }
});
