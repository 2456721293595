class Teaser {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$teaserFigure = this.$el.querySelector('.figure__media');
    this.$teaserContent = this.$el.querySelector('.teaser__content');

    // Add events
    this.$teaserContent.addEventListener('click', () => {
      this.$teaserFigure.click();
    });
  }
}

document.querySelectorAll('.teaser--video').forEach($el => new Teaser($el));
