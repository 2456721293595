import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from '../../_particles/icon/generate-icon';

export default function iconButton({
  tag,
  type,
  title = false,
  icon,
  link,
  disabled = false,
  classes = false,
  large = false,
  white = false,
}) {
  const $iconButton = h(`${(link && 'a') || tag || 'button'}`, {
    className: classNames(
      'icon-button',
      large && 'icon-button--large',
      white && 'icon-button--white',
      classes && classes,
    ),
    href: link,
    disabled,
    type: type || (!link && 'button'),
  }, generateIcon({ title, icon, classes: ['icon-button__icon'] }));

  return $iconButton;
}
