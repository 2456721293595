import h from 'hyperscript';
import classNames from 'classnames';
import randomId from 'javascripts/utils/random-id';

export default function label({
  tag,
  invalid = false,
  disabled = false,
  hidden = false,
  classes = false,
  labelFor,
  text = 'Label',
  required = false,
}) {
  const $required = required && h('abbr.label__required', { title: 'Pflichtfeld' }, '*');

  const $label = h(tag || 'label', {
    className: classNames(
      'label',
      invalid && 'label--invalid',
      disabled && 'label--disabled',
      hidden && 'u-hidden-visually',
      classes && classes,
    ),
    attrs: {
      for: (!tag) && (labelFor || `labelFor-${randomId()}`),
    },
  }, text);

  if ($required) $label.appendChild($required);

  return $label;
}
