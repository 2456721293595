import h from 'hyperscript';
import classNames from 'classnames';

export default function Select({
  id,
  name,
  disabled = false,
  required = false,
  invalid = false,
  descriptionId = false,
  labelId = false,
  errorId = false,
  classes = false,
  hasPleaseSelectOption = true,
  pleaseSelectOptionText = 'Bitte Auswählen',
  options = [
    {
      value: 'Value 1',
      text: 'Option 1',
    },
    {
      value: 'Value 2',
      text: 'Option 2',
    },
  ],
}) {
  let $pleaseSelectOptionEl = false;

  if (hasPleaseSelectOption) {
    $pleaseSelectOptionEl = h('option.select__hint', {
      disabled: 'disabled',
      selected: 'selected',
      value: '',
    }, pleaseSelectOptionText);
  }

  const $select = h('select', {
    className: classNames(
      'select',
      classes && classes,
    ),
    id,
    name,
    required,
    disabled,
    'aria-invalid': invalid && 'true',
    'aria-describedby': descriptionId,
    'aria-labelledby': labelId,
    'aria-errormessage': errorId,
  });

  if ($pleaseSelectOptionEl) $select.appendChild($pleaseSelectOptionEl);

  if (options) {
    options.forEach((option) => {
      const $optionEl = h('option', {
        value: option.value || option.uid,
        selected: option.selected,
      }, option.text || option.title);

      $select.appendChild($optionEl);
    });
  }

  return $select;
}
